import request from '../utils/request'

// v1/websocket/stock_holdings
export function getHoldings() {
    return request({
        method: 'get',
        url: '/api/v1/websocket/stock_holdings',
        params: {}
    })
}


export function getStockDetail(id) {
    return request({
        method: 'get',
        url: '/api/v1/websocket/strategy_stock',
        params: {
            stock_strategy_id: id
        }
    })
}

export function getStockDetailHistory(id, year) {
    return request({
        method: 'get',
        url: '/api/v1/websocket/strategy_stock',
        params: {
            stock_strategy_id: id,
            year: year
        }
    })
}

//获取股票信息
export function getStockData(data) {
    return request({
        method: 'get',
        url: '/api/v1/notify/notice_plan',
        params: data
    })
}