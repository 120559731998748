<template>
  <div style='position: relative;min-height: 835px;'>
    <div class="home">
      <van-search
          clearable
          left-icon=""
          input-align="center"
          placeholder="请输入策略名词/全拼/字母"
          v-model="search"
          input-class="search-input"
      />

      <van-swipe class="my-swipe" indicator-color="#ddd" :initial-swipe="swipe_index">
        <van-swipe-item v-for="(swiper_child, s_index) in swiper_list" v-bind:key="s_index">
          <div class="top-wrap">
            <div
                class="top-item"
                :class="{selected: item.selected}"
                v-for="(item, i_index) in swiper_child"
                v-bind:key="i_index"
                @click="handlerChangeList(s_index * 3 + i_index,s_index,i_index)"
            >
              <top-block :info="item"></top-block>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>

      <div class="table">
        <div class="row header">
          <div class="col col-1">策略</div>
          <div class="col">当前收益率</div>
          <!--        <div class="col">累计收益</div>-->
          <div class="col">累计收益率</div>
        </div>
        <div class="table_main" v-if="this.list !== {}">
          <div
              @click="handlerGoDetail(item)"
              v-for="item in show_table_list"
              v-bind:key="item.name"
              class="row value"
          >
            <div class="col col-1">
              <div class="name">{{ item.alias }}</div>
              <!--          <div class="sub-name">{{ item.sub_name }}</div>-->
            </div>
            <!--          <div class="col">{{ getSymbolString(item.profit, 2) }}</div>-->
            <div class="col">{{ getPrecentWith0(item.earning_revenue, item.pos_equity, 2) }}</div>
            <div class="col"><span class="label"
                                   :class="{ 'red-bg': item.cur_rate > 0, 'green-bg': item.cur_rate < 0 }">{{
                getPrecent(item.cur_rate, 2)
              }}</span>
            </div>
          </div>
        </div>
        <div v-else
             style="color: #fffbe8;border-top: 1px solid rgba(255,255,255,0.2);padding-top: 10px;font-size: 15px">暂无数据
        </div>
      </div>
    </div>
    <VersionNum style="position:absolute;left:0;bottom:0;"></VersionNum>
  </div>

</template>
<script>
import topBlock from "./com/top-block";
import {getHoldings} from "@/api/stock";
import InfoCommen from "../../mixins/InfoCommen";
import VersionNum from "../com/version_num";

let timer = null
export default {
  components: {
    topBlock,
    VersionNum
  },
  mixins: [InfoCommen],
  data() {
    return {
      search: "",
      swiper_list: [],
      list: [],
      table_list: [],
      source_list: [],
      selectIndex: [0, 0, 0],
      account_id: '',
      swipe_index: 0,
    };
  },
  computed: {
    show_table_list() {
      if (this.search) {
        return this.table_list.filter(item => {
          const title = String(item.name).toLowerCase()
          return title.includes(this.search.toLowerCase())
        })
      } else {
        return this.table_list
      }
    }
  },
  methods: {

    handlerGoDetail(row) {
      // console.log(row)
      this.$router.push({
        path: "/stock/detail", query: {
          account_id: row.stock_account_id,
          stock_strategy_id: row.stock_strategy_id,
        }
      });
    },
    handlerGetHoldings() {
      return getHoldings()
          .then((result) => {
            const list = result.data;
            this.source_list = list;
            this.list = list.map((item) => {
              // todo edit by mg 240323 修改实盘账号收益率使用真实收益率展示
              const status_value = item.pos_equity + item.earning_revenue;
              const value1 = status_value - item.init_asset;
              let real_cum_rate = item.cur_rate
              if (item.is_remote){
                real_cum_rate = item.init_asset ? (item.cumulative_revenue / item.init_asset) : 0
              }

              // console.log("!!!!!!!!!!",real_cum_rate)
              return {
                title: item.ac_nick_name,
                alias: item.alias,
                // status: item.cur_rate >= 0 ? "up" : "down",
                status: real_cum_rate >= 0 ? "up" : "down",
                status_value,
                value1: value1,
                value2: item.init_asset ? (status_value / item.init_asset - 1) : 0,
                cur_rate: real_cum_rate,
                selected: false,
              };
            });
            let swiper_count = 0;
            const swiper_list = [];
            let swiper_child = [];
            for (let i = 0; i < this.list.length; i++) {
              if (swiper_count < 3) {
                swiper_child.push(this.list[i]);
                swiper_count++;
              } else {
                swiper_list.push(swiper_child);
                swiper_child = [this.list[i]];
                swiper_count = 1;
              }
            }
            if (swiper_child.length) {
              swiper_list.push(swiper_child);
            }
            this.swiper_list = swiper_list;
            if (this.account_id !== '') {
              for (let i = 0; i < this.source_list.length; i++) {
                if (this.source_list[i]._id == this.account_id) {
                  let s_index = i / 3 ^ 0
                  let i_index = i % 3
                  this.swipe_index = s_index
                  // console.log("aaaaa")
                  // console.log(i, s_index , i_index)
                  this.handlerChangeList(i, s_index, i_index)
                }
              }
              this.account_id = ''
            } else {
              console.log(this.selectIndex)
              this.handlerChangeList(this.selectIndex[0], this.selectIndex[1], this.selectIndex[2])
            }

          })
          .catch(() => {
          });
    },
    handlerChangeList(index, s_index, i_index) {
      this.selectIndex = [
        index,
        s_index,
        i_index
      ]
      const info = this.source_list[index];
      this.swiper_list.forEach(child => {
        child.forEach(item => {
          item.selected = false
        })
      })
      this.swiper_list[this.selectIndex[1]][this.selectIndex[2]]['selected'] = true


      this.table_list = info.position_info.map((item) => {
        const profit = item.earning_revenue;
        return {
          name: item.stock_strategy_name,
          alias: item.alias,
          sub_name: "",
          new_price: item.cumulative_revenue_actual,
          profit: profit,
          rate: item.init_asset ? item.cumulative_revenue_actual / item.init_asset : 0,
          stock_strategy_id: item.stock_strategy_id,
          stock_account_id: this.source_list[index]._id,
          earning_revenue: item.earning_revenue,
          pos_equity: item.pos_equity,
          cur_rate: item.cur_rate
        };
      });
      // console.log(this.table_list)
    },
  },
  mounted() {
    this.handlerGetHoldings().then(() => {
    });
    timer = setInterval(() => {
      this.handlerGetHoldings()
    }, 10000)
  },
  destroyed() {
    if (timer) {
      clearInterval(timer)
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/stock/detail") {
      next(vm => {
        vm.account_id = from.query.account_id
      })
    }
    next(vm => {
      vm.handlerGetHoldings()
    })
  }

};
</script>

<style lang="less" scoped>
.top-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .top-item {
    width: 33%;
    overflow: hidden;
  }

  .selected {
    //border: 1px dotted #fff;
    background-color: #333;
  }
}

.table {
  text-align: center;
  line-height: 2rem;

  .row {
    display: flex;
    margin: 10px 5px;

    .col {
      width: 33%;
      overflow: hidden;
    }

    .col-1 {
      text-align: left;
      width: 33%;

      .name {
        font-size: 0.9rem;

      }

      .sub-name {
        color: #8b8a8a;
        font-size: 0.8rem;
        line-height: 1.2rem;

      }
    }

    .label {
      padding: 3px 10px;
    }
  }

  .header {
    color: #4b74ab;
    font-size: 0.8rem;

  }
}
</style>
